@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
}
body
{
  background-color: #050230;
}
.bg-image {
  position: fixed;
  top: 0; 
  right: 0;
  z-index: 1;
}
@media (min-width: 200px), (max-width: 425px) {
  .bg-image {
    display: none;
  }
}
.css-5xe99f-MuiLinearProgress-bar1{
    background-color: green !important;
}
.css-1kuv0w2-MuiLinearProgress-root{
  background-color: white !important;
}

/* Display the second column on larger screens */
@media (min-width: 1024px), (min-width: 768px), (min-width: 1440px), (min-width: 2500px) {
  .bg-image {
    display: block;
  }
}
@media (max-width:1024px) {
  .bg-image img{
   width: 350px;
   display: flex;
   justify-content: center;
   }
  .bg-image {
    font-size: 15px;
    margin: 20px 0px 0px 50px;
   }
   .bg-image p{
    font-size: 15px;
   }
}
@media (min-width:1440px)  {
  .bg-image{
    font-size: 50px;
  }
}

@media (min-width: 1024px) {
  .lg-bg-image-column {
    position: fixed;
  }

  .lg-w-530 {
    width: 530px;
  }
}
.bottom-element {
  position: absolute;
  bottom: 0;
  left: 0;
}
.footer{
  position: fixed;
  bottom: 0%;
  left: 0%;
  width: 520px;
}
@media (max-width: 1023px){
  .footer {
    width: 420px;
  }
}
@media (max-width: 425px){
  .footer {
    width: 100%;
  }
}
.header{
  position: fixed;
 box-shadow: rgb(17, 24, 39) 0px -15px 15px;
 width: 100%;
 z-index: 10;
 /* width: 520px; */
}
@media (max-width: 1024px){
  .header {
    /* width: 420px; */
    font-size: 10px;
  }
}
@media (max-width: 425px){
  .header {
    position: relative;
    font-size: 10px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .nav_menu{
    width: 420px;
  }
}
@media (max-width:1023px) {
  .nav_menu{
    width: 420px;
  }
}
@media (min-width: 1024px){
  .nav_menu {
    width: 520px;
  }
}
@media (max-width: 425px){
  .nav_menu {
    width: 100%;
  }
}
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5); 
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modal {
  background-color:#050230;
  color: white;
  border: 2px solid  white;
  padding: 20px;
  border-radius: 20px;
  width: 620px; 
  height: auto; 
}
.Toastify__zoom-enter {
  width: 15% !important;
}
@media  (max-width: 2560px) and (min-width: 1500px)   {
  .bg-height{
    height: 100vh !important;
  }
}

@media  (max-width: 1024px) and (min-width: 769px)   {
  .bg-image{
    margin-right: 9%;
  }
}
.scrollhost::-webkit-scrollbar {
  display: none;
}

.scrollhost ::-moz-scrollbar {
  display: none;
 
}

.scrollhost {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent; /*just hides the scrollbar for firefox */
}
::-webkit-scrollbar {
  width: 0;
}